import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#1264f6",
				secondary: "#f3f4fb",
				error: "#ff3823"
			}
		}
	}
});

export default vuetify;
