import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: "/Daily123"
	},
	{
		path: "/Daily123",
		name: "Daily123",
		component: () => import("@/views/Daily123.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/Authentication/Login.vue"),
		meta: {
			guest: true
		}
	},
	// {
	// 	path: "/register",
	// 	name: "Register",
	// 	component: () => import("@/views/Authentication/Register.vue"),
	// 	meta: {
	// 		requiresAuth: true
	// 	}
	// }
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

//redirects user if trying to access a page that requires login
router.beforeEach(async (to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (store.getters.isLoggedIn) {
			next();
			return;
		}
        else {
            try {
                next('/login');
            }catch(error) {
                console.error(`error: ${error}`);
            }
        }
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.guest)) {
		if (store.getters.isLoggedIn) {
			next("/");
			return;
		}
		next();
	} else {
		next();
	}
});

export default router;
