<template>
	<v-app id="inspire">
		<v-main class="grey lighten-3" v-resize="onResize">
			<v-container fluid class="main-contianer py-0">
				<Navigation />
				<router-view></router-view>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";

export default {
	components: {
		Navigation
	},
	data: () => ({}),
	methods: {
		onResize() {
			this.sleep(250).then(() => {
				this.$store.dispatch("isMobile", this.$vuetify.breakpoint.mobile);
			});
		},

		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		}
	},
	mounted() {
		this.onResize();
	}
};
</script>

<style lang="scss" scoped>
.v-main {
	.container {
		display: flex;
		height: 100%;
	}
}
</style>

<style lang="scss">
//***VUETIFY OVERIDES***
.v-sheet {
	.v-list-item .v-list-item__title {
		font-size: 1.4rem;
		line-height: 1.4rem;
	}
}

.v-list-item__title {
	font-size: 12px !important;
}

//***BASE STYLING***
html {
	font-size: 62.5%; /*changes browser default of 16px to font size default of 1rem = 10px*/
	overflow: auto;

	// @include respond(phone) {
	//     font-size: 50%; //1rem = 8px, 8/16=50%
	// }

	// @include respond(tablet) {//this will work for phone as well
	//     font-size: 50%; //1rem = 8px, 8/16=50%
	// }

	// @include respond(laptop) {
	//     font-size: 56.25%; //1rem = 9px, 9/16=56.25%
	// }

	// @include respond(desktop-lg) {
	//     font-size: 75%; //1rem = 12px, 12/16=75%
	// }

	// @include respond(desktop-xl) {
	//     font-size: 75%; //1rem = 12px, 12/16=75%
	// }
}

body {
	font-weight: 400;
	font-size: 1.6rem;
	color: $color-black;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	overflow-x: hidden;
	background-color: #eeeeee;
}

::-webkit-scrollbar {
	width: 5px;
	background: $color-white;
	border: 1px solid $color-gray;
}

::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	background: $color-gray;
}

//***LAYOUT***
.sidebar {
	&-search {
		background-color: $color-white;
		padding-bottom: 3rem;

		&-container {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		&-form {
			border: 1px solid $color-gray;
			border-radius: 3px;
			padding: 0.8rem 1.5rem;
			margin-bottom: 2rem;

			form {
				display: flex;
				flex-flow: column wrap;
				margin-top: 1rem;
				.col {
					margin-bottom: 0.8rem;
				}

				.clear-btn {
					margin-top: 0.5rem;
					align-self: flex-end;
					text-decoration: none;
					font-size: 1.2rem;
					color: $color-primary;
					transition: 0.3s all;

					&:hover {
						cursor: pointer;
						opacity: 0.8;
					}

					&:focus {
						outline: none;
					}
				}

				.v-label {
					font-size: 1.4rem;
				}

				.v-input--selection-controls {
					margin-top: 0;
					padding-bottom: 4px;
				}

				.v-btn {
					font-size: 1.4rem;
				}
			}
		}
	}
}
.package {
	&-main {
		display: flex;
		flex-flow: column;
		padding: 2.5rem 3rem;

		.flex-none {
			flex: initial;
		}
	}

	&-section {
		display: flex;
		flex-flow: column;
		padding-top: 0;
		padding-bottom: 0;

		&:nth-child(2) {
			padding-left: 2rem;
		}

		&-heading {
			position: relative;

			h2 {
				display: inline-block;
				font-size: 1.6rem;
				font-weight: normal;
			}

			&-actions {
				position: absolute;
				bottom: 0.6rem;
				right: 0;

				.v-btn {
					background-color: $color-white;
					font-size: 1rem;
					margin-left: 1rem;

					&--outlined {
						border-color: $color-gray;
					}
				}
			}
		}
	}

	&-details {
		display: flex;
		flex-flow: column;
		width: 100%;
		padding: 0.8rem 1.2rem;
		font-size: 1.2rem;

		.row,
		.col {
			margin: 0;
			padding: 0;
		}

		.row {
			min-height: 3.2rem;
			align-items: center;

			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}

		.col:not(:last-child) {
			padding-right: 1rem;
		}

		label {
			margin-right: 0.5rem;
		}

		.v-text-field {
			display: inline-flex;
			padding: 0;
			margin: 0;
			font-size: 1.2rem;
			font-weight: 700;
			text-transform: uppercase;
			transition: all 0.3s;

			&.v-input--is-readonly {
				//overrides selection highlighting
				color: inherit !important;

				fieldset {
					border: none;
				}
				.v-input__slot {
					padding: 0;
				}
				.v-select__selection {
					color: inherit;
				}
				.v-input__icon {
					display: none;
				}
			}

			&.v-text-field--outlined {
				.v-input__slot {
					min-height: unset !important;
					padding: 0 5px !important;
				}

				.v-select__selections {
					padding: 0 !important;

					input {
						width: 1rem;
					}
				}
				.v-input__append-inner {
					margin-top: 0.3rem !important;
				}
			}

			&.v-select--chips {
				&.v-input--is-readonly {
					.v-input__slot:before,
					.v-input__slot:after {
						content: none;
					}
				}

				.v-select__selections {
					min-height: inherit !important;

					.v-chip--select {
						font-size: 1.2rem;
						height: 22px;
					}
				}
			}
		}

		.v-input {
			display: inline-flex;
			width: 100%;

			.v-label {
				font-size: 1.2rem;
				margin-left: -4px;
			}
		}

		.v-input--selection-controls {
			margin: 0;
			padding: 0;
			margin-left: -4px;
		}

		.v-textarea {
			&.v-input--is-readonly {
				border: 1px solid $color-gray;

				&.v-input--is-focused {
					.v-label {
						color: inherit !important;
						left: 12px;
					}
				}

				legend {
					background-color: $color-white;
				}
			}

			.v-text-field__slot {
				margin-right: -5px !important;
			}

			.v-label {
				top: 6px !important;
				left: 12px !important;
			}

			.v-label--active {
				top: 16px !important;
			}
		}
	}

	&-items {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column nowrap;

		&-list {
			flex: 1 1 auto;
			overflow-y: scroll;

			&.v-list--disabled {
				opacity: 0.5;
			}

			.v-list-group {
				.v-list-group__header {
					min-height: 30px;

					.v-list-item__content {
						padding: 8px 0;
						padding-top: 10px;
					}
				}

				.v-list-group__items {
					.v-list-item {
						margin-left: 2.5rem;
					}
				}
			}

			.v-list-item--dense {
				min-height: 30px;
			}

			.v-list-item__icon {
				height: initial;
				margin: auto;
				margin-right: 0.25rem !important;
				align-items: flex-end;

				i {
					font-size: 1.6rem;

					&.module {
						color: $color-primary;
					}

					&.part {
						color: $color-green;
					}

					&.comment {
						color: $color-orange;
					}
				}
			}

			.selected-item {
				transition: all 0.5s;
				background-color: $color-secondary;
			}

			.v-list-item__content {
				padding-top: 10px;
			}

			.v-list-item__title {
				font-size: 1.4rem;
				line-height: 1.4rem;
				font-weight: normal;
			}
		}
	}

	&-pricing {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column;
		padding: 0.8rem 1.2rem;
		font-size: 1.2rem;

		form {
			display: flex;
			flex-flow: column;
			height: 100%;
			justify-content: center;
		}

		&-group {
			position: relative;

			&:not(:last-child)::after {
				content: "";
				position: absolute;
				bottom: 2px;
				right: 0;
				width: 76px;
				border-bottom: 2px solid $color-black;
			}
		}

		&-item {
			text-align: right;
			margin-bottom: 0.6rem;

			label {
				margin-right: 0.8rem;
			}

			.v-text-field {
				display: inline-flex;
				justify-content: flex-end;
				padding: 0;
				margin: 0;
				font-size: 1.2rem;
				font-weight: normal;

				&.v-input--is-disabled {
					background-color: $color-gray-light;
				}

				&:read-only {
					fieldset {
						border-color: $color-gray;
					}
				}

				.v-input__control > .v-input__slot {
					padding: 0 6px !important;
					min-height: unset;
				}

				input {
					text-align: right;
				}
			}
		}
	}
}
</style>
