import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
//modules
import authentication from "./modules/authentication.js";
import responsive from "./modules/responsive.js";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		authentication,
		responsive
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage
		})
	],
	state() {
		return {
			msalConfig: {
				auth: {
					clientId: "96d2671a-79de-473c-9de2-08b84b6ec545",
					authority: "https://login.microsoftonline.com/1d0fd13b-b5e1-4ed7-b396-8604e91fa331/"
				},
				cache: {
					cacheLocation: "localStorage"
				}
			},
			accessToken: ""
		};
	},
	mutations: {
		setAccessToken(state, token) {
			state.accessToken = token;
		}
	}
});
