<template>
	<v-app id="inspire">

		<!-- This drawer is for future navigatioln needs. -Anthony -->
		<!-- <v-navigation-drawer v-model="drawer" app></v-navigation-drawer> -->

		<v-app-bar app color="#1a4a58" flat height="92" style="z-index:999999">

			<!-- This nav icon is for responsive toggling of the navigation drawer for future use. -Anthony -->
			<!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->

			<v-container fluid class="py-0" style="height: 100%;">
				<v-row align="center" style="height: 100%; margin-left: 12px;">
					<img src="@/assets/fulllogo.svg" height="40" />
					<v-spacer />
				</v-row>
			</v-container>
		</v-app-bar>
	</v-app>
</template>

<script>
export default {
	name: "Navigation",
	data: () => ({
		links: [
			{
				name: "Texas Farm Bureau",
				to: "/bids/plans"
			}
		],
		drawer: null
	}),
	methods: {
		logout() {
			this.$store
				.dispatch("logout")
				.then(() => {
					this.$router.push("/login");
					clearInterval(this.authTimer);
				})
				.catch(error => {
					console.log(error);
				});
		},

		async checkAuthStatus() {
			console.log("checking auth status");
			try {
				const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/api/auth`);

				if (res.status !== 200) {
					console.log("Authorization expired, logging out");
					this.logout();
				}

				console.log("Authorization Status: ", res.status);
			} catch (error) {
				console.log(error);
			}
		}
	},
	computed: {
		isLoggedIn() {
			return this.$store.getters.isLoggedIn;
		}
	},
	created() {
		//this.authTimer = setInterval(() => this.checkAuthStatus(), 300000); //5 minutes
	}
};
</script>

<style lang="scss" scoped>
.avatar {
	&-menu {
		padding: 0.2rem 0;
	}
}

::v-deep {
	.v-toolbar__content {
		padding: 0;
		height: 100% !important;
	}

	.v-tabs {
		width: unset;

		.v-tab {
			font-size: 1.2rem;
		}
	}

	.v-btn {
		&:not(:last-child) {
			margin-right: 1rem;
		}

		&--outlined {
			border-color: $color-gray;
		}
	}
}

.centered {
	position: absolute;
	top: 65%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>
