import { PublicClientApplication } from "@azure/msal-browser";

const state = {
	status: "",
	user: null,
	msalConfig: {
		auth: {
			clientId: "96d2671a-79de-473c-9de2-08b84b6ec545",
			authority: "https://login.microsoftonline.com/1d0fd13b-b5e1-4ed7-b396-8604e91fa331/"
		},
		cache: {
			cacheLocation: "localStorage"
		}
	},
	token: "",
	slackConfig: null
};

const getters = {
	isLoggedIn(state) {
		return !!state.user;
	},
	authStatus: state => state.status,
	userInfo: state => {
		return state.user;
	},
	madConfig: state => {
		return state.msalConfig;
	},
	accessToken: state => {
		return state.token;
	},
	slackConfig: state => {
		return state.slackConfig;
	}
};

const mutations = {
	auth_request(state) {
		state.status = "loading";
	},
	auth_success(state, response) {
		state.status = "success";
		state.user = response;
	},
	auth_error(state) {
		state.status = "error";
	},
	logout(state) {
		state.status = "";
		state.user = null;
	},
	token_acquired(state, response) {
		state.token = response;
	},
	set_slack_config(state, response) {
		state.slackConfig = response;
	}
};

const actions = {
	async signIn({ commit }) {
		const msalInstance = new PublicClientApplication(state.msalConfig);
		commit("auth_request");

		// Handle the redirect flows
		await msalInstance
			.handleRedirectPromise()
			.then(tokenResponse => {
				// Handle redirect response
				commit("auth_success", tokenResponse);
			})
			.catch(error => {
				console.log("FAIL", error);
				// Handle redirect error
			});

		await msalInstance.loginRedirect();
	},

	async saveToken({ commit }, token) {
		commit("token_acquired", token);
	},

	setSlackConfig({ commit }, response) {
		if (response) {
			commit("set_slack_config", response);
		}
	}
};

export default {
	state,
	mutations,
	actions,
	getters
};
