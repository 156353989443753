const state = {
	isMobile: null
};

const getters = {
	isMobile: state => {
		return state.isMobile;
	}
};

const mutations = {
	set_mobile(state, isMobile) {
		state.isMobile = isMobile;
	}
};

const actions = {
	isMobile({ commit }, isMobile) {
		commit("set_mobile", isMobile);
	}
};

export default {
	state,
	mutations,
	actions,
	getters
};
